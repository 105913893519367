// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ko-index-js": () => import("./../../../src/pages/ko/index.js" /* webpackChunkName: "component---src-pages-ko-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-kontakt-sukces-js": () => import("./../../../src/pages/kontakt/sukces.js" /* webpackChunkName: "component---src-pages-kontakt-sukces-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-generalne-wykonawstwo-js": () => import("./../../../src/pages/oferta/generalne-wykonawstwo.js" /* webpackChunkName: "component---src-pages-oferta-generalne-wykonawstwo-js" */),
  "component---src-pages-oferta-index-js": () => import("./../../../src/pages/oferta/index.js" /* webpackChunkName: "component---src-pages-oferta-index-js" */),
  "component---src-pages-oferta-projektowanie-js": () => import("./../../../src/pages/oferta/projektowanie.js" /* webpackChunkName: "component---src-pages-oferta-projektowanie-js" */),
  "component---src-pages-oferta-serwis-js": () => import("./../../../src/pages/oferta/serwis.js" /* webpackChunkName: "component---src-pages-oferta-serwis-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-career-page-js": () => import("./../../../src/templates/career-page.js" /* webpackChunkName: "component---src-templates-career-page-js" */),
  "component---src-templates-career-post-js": () => import("./../../../src/templates/career-post.js" /* webpackChunkName: "component---src-templates-career-post-js" */),
  "component---src-templates-eu-post-js": () => import("./../../../src/templates/eu-post.js" /* webpackChunkName: "component---src-templates-eu-post-js" */),
  "component---src-templates-portfolio-page-js": () => import("./../../../src/templates/portfolio-page.js" /* webpackChunkName: "component---src-templates-portfolio-page-js" */),
  "component---src-templates-portfolio-post-js": () => import("./../../../src/templates/portfolio-post.js" /* webpackChunkName: "component---src-templates-portfolio-post-js" */),
  "component---src-templates-sponsoring-post-js": () => import("./../../../src/templates/sponsoring-post.js" /* webpackChunkName: "component---src-templates-sponsoring-post-js" */)
}

